@font-face {
  font-family: "CH";
  src: url("Chivo-Regular.woff2") format("woff2"), url("Chivo-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "CH";
  src: url("Chivo-Bold.woff2") format("woff2"), url("Chivo-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "CH";
  src: url("Chivo-BoldItalic.woff2") format("woff2"), url("Chivo-BoldItalic.woff") format("woff");
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "SK";
  src: url("SkolarSansPECn-Eb.woff2") format("woff2"), url("SkolarSansPECn-Eb.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}
